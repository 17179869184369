.container {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 100;
  height: 85vh;
  padding: 2rem;
  color: aliceblue;
}

.container a {
  color: aliceblue;
  width: 100%;
  border-radius: 0.5rem;
  background-color: #0089cc;
  font-size: 1rem;
  padding: 0.5rem;
  text-align: center;
  margin: 1rem;
  width: min(100%, 350px);
}

.container a:hover {
  opacity: 1;
  cursor: pointer;
}