.card {
  /* border: 1px solid blue; */
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 13rem;
  /* height: 90vh; */
}

.card img {
  margin: 0;
  border: 2px solid gray;
  /* margin-top: -20%; */
  width: 100%;
  border-radius: 1rem;
}

.button_download:link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-style: none;
  color: #35a69b;
  background-color: #111111;
  border: 1px solid #35a69b;
  height: 1.7rem;
  margin: 0.5rem;
  border-radius: 0.3rem;
  cursor: pointer;
  font-size: 0.8rem;
}

.button_download:hover {

}

.button_add {
  border-style: none;
  color: #f5f5f8;
  color: #de8200;
  background-color: #111111;
  border: 1px solid #de8200;
  height: 2rem;
  margin: 0.5rem;
  /* width: 150px; */
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 0.8rem;
}


@media only screen and (max-width: 320px) {
  .card {
    width: 9rem;
  }
}

/* Only for iphone8 */
@media only screen and (width: 375px) and (max-height:610px) {
  .card {
    /* border: 2px solid green; */
    width: 10rem;
  }
}


@media only screen and (min-width: 390px) {
  .card {
    /* border: 2px solid green; */
    width: 14rem;
    /* height: 90vh; */
  }
}

@media only screen and (width: 414px) {
  .card {
    /* border: 2px solid green; */
    width: 13rem;
  }
}