.top_container {
  width: 100%;
  height: 2.7rem;
}

.container {
  /* border: 1px solid orange; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
}

.payment_title {
  /* border: 1px solid blue; */
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  font-weight: bold;
  text-align: center;
}

.background {
  /* border: 1px solid blue; */
  width: min(100%, 500px);
  padding: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 1800px;

}

.detail {
  /* border: 1px solid blue; */
  width: 100%;
}

.total {
  display: flex;
  justify-content:space-between;
  margin: 1rem 0 1rem 0;
}

.total span{
  color: #0b757a;
  font-weight: bold;
  padding-right: 0.5rem;
}

.number_container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.currency {
  font-size: 0.7rem;
  color: rgb(91, 91, 91);
  padding-right: 0.3rem;
}

.button_container {
  width:100%;
  max-height: 100px;
  /* border: 1px solid red; */
}

.description {
  width: 100%;
  font-size: 0.7rem;
  margin-bottom: 2rem;
}

