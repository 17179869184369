/* apps.module.css */
.page_container {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(135deg, #121212, #1e1e1e, #262626);
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
}

.top_container {
  width: 100%;
  height: 2.7rem;
}

.hero {
  width: 100%;
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 1rem;
}

.content_container {
  max-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.app_info {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.app_icon {
  width: 120px;
  height: 120px;
  border-radius: 24px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  margin-bottom: 1.5rem;
}

.title {
  font-size: 3rem;
  font-weight: 700;
  margin: 0;
  font-family: 'Philosopher', sans-serif;
  background: linear-gradient(90deg, #ffffff, #7e57c2);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subtitle {
  font-size: 1.2rem;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  opacity: 0.9;
}

.features_container {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin-bottom: 2.5rem;
}

.feature {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  font-size: 1.1rem;
}

.feature_icon {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
}

.google_play_button {
  display: inline-block;
  margin-top: 1rem;
  transition: transform 0.3s ease;
  filter: drop-shadow(0 6px 12px rgba(0, 0, 0, 0.2));
}

.google_play_button:hover {
  transform: translateY(-3px);
  filter: drop-shadow(0 8px 15px rgba(0, 0, 0, 0.3));
}

.google_play_image {
  width: 300px;
  height: auto;
}

.device_preview {
  width: 40%;
  display: flex;
  justify-content: center;
}

.phone_mockup {
  max-width: 320px;
  height: auto;
  filter: drop-shadow(0 20px 30px rgba(0, 0, 0, 0.25));
}

.screenshots_section {
  padding: 4rem 2rem;
  background-color: rgba(40, 40, 40, 0.7);
  backdrop-filter: blur(10px);
  text-align: center;
}

.screenshots_section h2 {
  font-family: 'Philosopher', sans-serif;
  font-size: 2.2rem;
  margin-bottom: 3rem;
}

.screenshots_container {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

.screenshot {
  width: 280px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.screenshot:hover {
  transform: translateY(-10px);
}

.screenshot img {
  width: 100%;
  height: auto;
  display: block;
}

.footer {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1.5rem;
  text-align: center;
  font-size: 0.9rem;
}

/* Responsive design */
@media screen and (max-width: 960px) {
  .content_container {
    flex-direction: column;
    gap: 3rem;
  }

  .app_info {
    width: 100%;
    align-items: center;
    text-align: center;
  }

  .features_container {
    align-items: center;
  }

  .device_preview {
    width: 100%;
  }

  .phone_mockup {
    max-height: 450px;
  }
}

@media screen and (max-width: 600px) {
  .hero {
    padding: 1rem;
  }

  .title {
    font-size: 2.5rem;
  }

  .screenshot {
    width: 100%;
    max-width: 280px;
  }
}