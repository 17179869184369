
.title {
  color: rgb(212, 212, 212);
  /* border: 1px solid red; */
  width: 100%;
  font-size: 1.3rem;
  padding-bottom: 0.3rem;
}

.transaction_container {
  /* border: 1px solid red; */
}

.user_email {
  color: aliceblue;
  font-size: 0.8rem;
  text-align: center;
  margin: 1rem;
}

.list li {
  /* border: 1px solid green; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #232323;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.left_block {
  /* border: 1px solid blue; */
}

.right_block {
  /* border: 1px solid orange; */
  color: aliceblue;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
}

.item_name {
  font-size: 0.8rem;
}

.left_block img {
  /* border: 1px dashed red ; */
  width: 100px;
  margin: 1rem;
}

.button_download:link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-style: none;
  color: #45bbf6;
  background-color: #111111;
  border: 1px solid #45bbf6;
  height: 2rem;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
  width: 100%;
}