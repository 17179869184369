
.top_container {
  /* border: 2px dashed yellow ; */
  width: 100%;
  height: 2.7rem;
}

.container {
  /* border: 1px solid blue; */
  width: 100%;
  display: flex;
  justify-content: center;
}

.form_container {
  color: aliceblue;
  /* border: 1px solid red; */
  width: min(100%, 600px);
  margin: 1rem;
}

.input {
  display: none;
}

.row {
  border: 1px solid rgb(52, 52, 52);
  display: flex;
  justify-content: space-between;
  padding: 0.3rem;
}

.row input {
  -webkit-text-fill-color: black !important;
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

.file {
  border: 1px solid rgb(52, 52, 52);
  display: flex;
  justify-content: space-between;
  padding: 0.3rem;
}

.uploadButton{
  background-color: #0089cc;
  color: aliceblue;
  padding: 0.2rem;
  border-radius: 0.3rem;
  margin: 0.5rem 0 0.5rem 0;
  font-size: 1rem;
  border-style: none;
  /* width: min(100%, 450px); */
  width: 143px;
  height: 35px;
}

.uploadButton:disabled{
  background-color: #3c3c3c;
  color: #6d6d6d;
}

.reload_button {
  background-color: #0089cc;
  color: aliceblue;
  padding: 0.2rem;
  border-radius: 0.3rem;
  margin: 0.5rem 0 0.5rem 0;
  font-size: 1rem;
  border-style: none;
  /* width: min(100%, 450px); */
  width: 160px;
  height: 35px;
}

.info_container {
  width: 70%;
  margin: 0.5rem;
}
.info_msg {
  width: 100%;
}