.top_container {
  /* border: 2px dashed yellow ; */
  width: 100%;
  height: 2.7rem;
}

.title {
  /* border: 1px solid green; */
  color:aliceblue;
  font-size: 1.8rem;
  font-family: 'Philosopher', sans-serif;
  text-align: center;
  margin-top: 6rem;
}

.hero {
  /* border: 1px solid green; */
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.image_container {
  /* border: 1px solid red; */
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (max-width: 960px) {
  .hero {
    width: 100%;
    height: 100vh;
  }
  .image_container {
    width: 300px;
  }
}