.upper_gap {
  /* border: 1px solid orange; */
  height: 200px;
  width: 100%;
}

.container {
  /* border: 1px solid blue; */
  background: linear-gradient(180deg,  rgb(71, 71, 71) 0%, rgba(0, 0, 0, 0.8) 5%
  ,  rgba(0, 0, 0, 0.8) 100%, rgba(0, 0, 0, 0.1) 100%);
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.content {
  /* border: 1px solid red; */
  width: 320px;
  color: antiquewhite;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:space-between;
}

.name {
  font-size: 0.7rem;
  color: rgb(197, 197, 197);
}

.policy {
  font-size: 0.6rem;
  font-weight: bold;
}

.suppliers {
  font-size: 0.6rem;
  font-weight: bold;
}