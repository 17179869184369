
.top_container {
  /* border: 2px dashed yellow ; */
  width: 100%;
  height: 2.7rem;
}

.container {
  color: aliceblue;
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  align-items: center;
  /* height: 100vh; */
}

.wallpaper_setup {
  /* border: 1px solid red; */
  width: min(100%, 600px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

h3 {
  margin: 1rem 0 1rem 0;
}

.title_img {
  width: 70%;
  margin: 1rem 0 5rem 0;
  border-radius: 0.5rem;
}

.instruction_img {
  width: 60%;
  margin: 1rem;
  border-radius: 0.5rem;
  border: 1px solid rgb(56, 56, 56);
  /* text-align: center; */
}

.instruction_img {
  width: 35%;
  margin-bottom: 3rem;
}

.content li{
  /* text-align: center; */
  display: flex;
  flex-direction: row;
  justify-content:space-around;
  align-items: center;
}

.googleAdSense {
  border: 1px dashed rgb(217, 217, 217);
  margin: 5rem;
  /* width: 100px; */
  /* height: 100px; */
}

@media screen and (max-width: 960px) {
  .content li{
    flex-direction: column;
    font-size: 0.8rem;
  }
  .instruction_img {
    width: 50%;
    margin-bottom: 5rem;
  }

}