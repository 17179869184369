.top_container {
  /* border: 2px dashed yellow ; */
  width: 100%;
  height: 2.7rem;
}

.container {
	width: 100vw;
	height: 80vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.green_btn {
	border: none;
	outline: none;
	padding: 12px 0;
	color: aliceblue;
	/* background-color: #3bb19b; */
	background-color: #0089cc;
	border-radius: 0.3rem;
	width: 180px;
	font-weight: bold;
	font-size: 1rem;
	cursor: pointer;
}

.content {
	/* border: 1px solid red; */
	display: flex;
	flex-direction: column;
	align-items: center;
}

.content img {
  width: 100px;
	margin: 1rem;
}

.content h3 {
	color: aliceblue;
	margin: 1rem;
}

.container button {
	margin-bottom: 200px;
}

.error_title {
	color: rgb(255, 255, 255);
	font-size: 1.2rem;
	font-weight: bold;
	margin-bottom: 1rem;
}

.login_in_here{
  color: rgb(255, 255, 255);
  font-size: 1rem;
  text-align: center;
}

.login_button {
  background-color: #0089cc;
  color: aliceblue;
  padding: 0.8rem;
  border-radius: 0.3rem;
  margin: 1rem;
  font-size: 1rem;
  border-style: none;
}