
.header {
  /* border: 1px solid red; */
  font-size: 1.5rem;
  text-align: center;
  /* margin: 1rem; */
  color: aliceblue;
}

.info {
  text-align: center;
  color: rgb(196, 196, 196);
  margin: 0.7rem;
  font-size: 0.8rem;
}

.login_here {
  color: #ffffff;
  font-size: 1.1rem;
  margin: 0.5rem;
  font-weight: bold;
}

.already {
  color: white;
  font-size: 1rem;
}

.notchedOutline {
  border-color:rgb(205, 205, 205) !important;
}

.error_msg,
.success_msg {
	margin: 0.2rem 0 1rem 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
   -webkit-box-shadow: 0 0 0 30px inset !important;
   -webkit-text-fill-color: white !important;
}

.login_again_button{
  background-color: #0089cc;
  color: aliceblue;
  padding: 0.8rem;
  border-radius: 0.3rem;
  margin: 1.1rem 0.5rem 1.5rem 0.5rem;
  font-size: 1rem;
  border-style: none;
  /* width: min(100%, 450px); */
  width: 300px;
}

.login_in_here{
  /* border: 1px solid red; */
  color: white;
  font-size: 1rem;
  text-align: center;
  padding: 0.5rem;
}

.login_in_here span{
  font-size: 0.8rem;
  text-decoration: underline;
}

.login_in_here strong {
  font-size: 1.3rem;
}

.hint_msg {
  font-size: 0.9rem;
}