.container {
  /* border: 1px solid orange; */
  margin-inline: auto;
  width: min(100%, 62.5rem);
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.list{
  /* border: 1px solid blue; */
  width: 85%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  list-style-type: none;
  /* column-gap: 3rem; */
  grid-row-gap: 3rem;
}

.list li {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 960px) {
  .container {
    /* border: 1px solid red; */
    margin: 0 0 0 0;
    width: 100%;
  }

  .list{
    width: 100%;
    grid-template-columns: 1fr;
  }
}
