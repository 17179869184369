.container {
  border: 2px dashed yellow ;
  width: min(100%, 450px);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reload_button{
  background-color: #0089cc;
  width: min(100%, 450px);
  color: aliceblue;
  padding: 0.8rem;
  border-radius: 0.3rem;
  margin: 2rem 0rem 0rem 0rem;
  font-size: 0.9rem;
  border-style: none;
}
