.top_container {
  /* border: 2px dashed yellow ; */
  width: 100%;
  height: 2.7rem;
}

.container {
  /* border: 1px solid rgb(21, 0, 255); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:center;
}

.successful_text {
  font-family: 'Philosopher', sans-serif;
  font-size: 1.6rem;
  color: aliceblue;
  margin: 3rem 0 1rem 0;
}

.cute_container {
  width: 200px;
  margin: 1.5rem;
}

.empty_box {
  width: 150px;
  height: 150px;
  border-radius: 0.5rem;
  background-color: #202020;
  margin: 1rem 1rem 2rem 0;
}

.download_container {
  /* border: 1px solid red; */
  width: min(100%, 250px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:center;
  margin: 0.5rem;
  cursor: pointer;
}

.hint_msg, .download_again {
  color: aliceblue;
  margin-right: 1rem;
}

.download_icon {
  width: 35px;
}

.go_back {
  border: none;
  background-color: #0089cc;
  padding: 0.5rem;
  border-radius: 0.3rem;
  width: min(100%, 250px);
  text-align: center;
  margin: 1.5rem;
}

.googleAdSense {
  border: 1px dashed rgb(217, 217, 217);
  /* width: 100px; */
  /* height: 100px; */
}