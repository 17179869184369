.content {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  /* padding: 20px; */
  color: aliceblue;
  /* text-align: center; */
}

.top_container {
  /* border: 2px dashed yellow ; */
  width: 100%;
  height: 2.7rem;
}