
.container {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.image {
  width: 200px;
  margin-top: 15%;
}

.title {
  color: aliceblue;
  font-size: 2rem;
  margin: 2rem 1rem 0rem 1rem;
  text-align: center;
}

.content {
  /* border: 1px solid red; */
  color: rgb(255, 255, 255);
  font-size: 1.2rem;
  width: 60%;
  text-align: center;
  margin: 1rem;
}

.line {
  margin: 10% 0 10% 0;
}

@media screen and (max-width: 960px) {
  .container {
    height: 100vh;
  }

  .content {
    width: 80%;
    font-size: 0.8rem;
  }

  .image {
    width: 100px;
    margin-top: 15%;
  }

  .title {
    font-size: 1.2rem;
  }

  .line {
    /* border: 1px solid green; */
    margin: 25% 0 0 0;
  }
}