.top_container {
  /* border: 2px dashed yellow ; */
  width: 100%;
  height: 2.7rem;
}

.container {
  /* border: 2px dashed yellow ; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* height: 85vh; */
  margin-bottom: 10%;
}

.successful_title {
  /* border: 1px dashed red ; */
  font-size: 1.5rem;
  width: 300px;
  text-align: center;
  margin-top: 2rem;
  color: aliceblue;
}

.background {
  /* border: 1px dashed yellow ; */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: min(100%, 350px);
}

.background img {
  /* border: 1px dashed red ; */
  width: 120px;
  margin: 2rem;
}

.info {
  color: aliceblue;
}

.done_button_link {
  width: 100%;
}

.done_button {
  /* margin: 1.5rem; */
  color: aliceblue;
  width: 100%;
  border-radius: 0.5rem;
  background-color: #0089cc;
  font-size: 1rem;
  padding: 0.5rem;
  text-align: center;
  margin-top: 2rem;
}

a:link, a:visited {
  color:aliceblue;
}

.left_block img {
  width: 100px;
}
