.top_container {
  /* border: 2px dashed yellow ; */
  width: 100%;
  height: 2.7rem;
}

.container {
  /* border: 3px solid red; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
  background: #000;
}

.form_container {
  /* border: 1px solid red; */
  /* background-color: green; */
  width: min(100%, 450px);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}

@media screen and (max-width: 960px) {
  .form_container {
    margin-top: 1rem;
  }
}