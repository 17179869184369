
.container {
  /* margin-inline: auto; */
  /* background-color: gray; */

  /* width: min(100%, 62.5rem); */
  /* width: min(100%, 91rem); */

  /* height: 5rem; */
  /* border: 2px solid red; */
  /* display:contents; */
  /* align-items: center; */
  /* justify-content: center; */
}

.section_image {
  /* width:  500px; */
  /* width: 100%; */
   /* height: 100vh; */
   /* width: 100vh; */
}

.googleAdSense {
  border: 1px dashed rgb(217, 217, 217);
  margin: 10% 10% 10% 10%;
  /* width: 100px; */
  /* height: 100px; */
}

@media screen and (max-width: 960px) {
  .googleAdSense {
    margin: 10% 5% 10% 5%;
  }
}