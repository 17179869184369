.container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  color: aliceblue;
  text-align: center;
}

.wallpaper_setup {
  background-color: #121212;
  border: 1px solid rgb(85, 85, 85);
  padding: 25px;
  border-radius: 10px;
  text-align: start;
}

.title {
  font-size: 2rem;
  font-weight: bold;
  margin: 15% 0 2rem 0;
}

.content {
  font-size: 16px;
  line-height: 1.6;
}

.content p {
  margin-bottom: 20px;
}

.img_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 3rem 0 3rem 0;
}

.article_img {
  width: 80%;
}

@media screen and (max-width: 960px) {
  .title {
    font-size: 1.2rem;
  }

  .article_img {
    width: 100%;
  }
}