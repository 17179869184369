.frame {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
}

.main_container {
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

/* Container for side-by-side images */
.images_container {
  display: flex;
  flex-direction: row;
  gap: 6px; /* Space between the two images */
  width: 90%;
  height: auto;
}

.image_wrapper {
  flex: 1; /* Each wrapper takes equal space */
  position: relative;
  aspect-ratio: 9/16; /* Maintain vertical phone aspect ratio */
  border-radius: 0.9rem;
  overflow: hidden;
}

.image {
  margin: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 0.7rem;
  border: 1px solid rgb(71, 71, 71);
}

/* Style for the empty preview placeholder */
.empty_preview {
  width: 100%;
  height: 100%;
  background-color: #000;
  border-radius: 0.7rem;
  border: 1px solid rgb(71, 71, 71);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Status text styling */
.status_text {
  font-family: 'Philosopher', sans-serif;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 10px 15px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.6);
  text-align: center;
}

.status_in_progress {
  color: #ffdd00; /* Yellow color for "In Progress" */
}

.status_not_started {
  color: #888888; /* Gray color for "Not Started" */
}

.right_container {
  width: 10%;
  padding-left: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
}

.download_icon {
  width: 33px;
}

.download_icon:hover {
  cursor: pointer;
}

.free_text {
  font-family: 'Philosopher', sans-serif;
  font-size: 0.9rem;
  margin: 0px 0px 10px 0px;
  font-weight: bold;
  color: #3fe303;
  text-align: center;
}

.add_cart {
  width: 33px;
}

.add_cart:hover {
  cursor: pointer;
}

.price {
  font-size: 0.7rem;
  margin: 0px 0px 10px 0px;
  font-weight: bold;
  color: #ffdd00;
  text-align: center;
}

.error_message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ff6b6b;
  background-color: rgba(34, 34, 34, 0.9);
  border: 1px solid #ff6b6b;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 0.9rem;
  text-align: center;
  width: 80%;
  max-width: 400px;
  z-index: 100;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

/* Add these styles to your review.frame.module.css file */

.confirm_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.confirm_dialog {
  background-color: #222;
  border: 1px solid #444;
  border-radius: 8px;
  padding: 20px;
  width: 300px;
  max-width: 80%;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.confirm_dialog p {
  color: white;
  margin-bottom: 20px;
  font-size: 16px;
}

.confirm_buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.confirm_yes,
.confirm_no {
  padding: 8px 20px;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s;
}

.confirm_yes {
  background-color: #e53935;
  color: white;
}

.confirm_yes:hover {
  background-color: #c62828;
}

.confirm_no {
  background-color: #424242;
  color: white;
}

.confirm_no:hover {
  background-color: #616161;
}

/* Mobile view adjustments */
@media screen and (max-width: 960px) {
  .main_container {
    width: 100%;
    flex-direction: row; /* Keep horizontal layout on mobile */
  }

  .images_container {
    width: 85%;
    gap: 4px; /* Reduce gap between images on small screens */
  }

  .image_wrapper {
    height: auto;
    aspect-ratio: 9/16; /* Maintain vertical phone aspect ratio */
  }

  .status_text {
    font-size: 1rem;
    padding: 8px 12px;
  }

  .right_container {
    width: 15%;
    padding-left: 4px;
    gap: 5px;
  }

  .download_icon, .add_cart {
    width: 28px; /* Slightly smaller icons on mobile */
  }

  .free_text {
    font-size: 0.7rem;
    margin: 0px 0px 5px 0px;
  }
}

/* Extra small screens */
@media screen and (max-width: 480px) {
  .images_container {
    width: 80%;
  }

  .status_text {
    font-size: 0.9rem;
    padding: 6px 10px;
  }

  .right_container {
    width: 20%;
  }

  .download_icon, .add_cart {
    width: 24px; /* Even smaller icons on very small screens */
  }
}