@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

html {
  font-size: 18px;
  /* scroll-behavior: smooth; */
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: sans-serif, sans-serif;
  /* background-color: #F2E9EB; */
  /* background-color: #111111; */
  background-color: black;

  /* background-color: rgba(245,245,247,255); */
}

a {
  text-decoration: none;
}

/* Pretty in Pink */
.my-color-palette {
  color: #F2E9EB;
  color: #DD8EA4;
  color: #FEDD89;
  color: #31383F;
}

/* latin */
@font-face {
  font-family: 'Philosopher';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/philosopher/v19/vEFV2_5QCwIS4_Dhez5jcWBuT0020Nqf.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}