:root {
  --mobile-nav-height: 2.7rem;
  --google-font-family: 'Philosopher', sans-serif;
}

.top_container {
  /* border: 2px dashed yellow ; */
  width: 100%;
  height: 2.7rem;
}

.fixed_position {
  position: fixed;
}

.navbar {
  /* background: linear-gradient(180deg,  rgb(32, 32, 32) 0%, rgba(9, 9, 9, 0.8) 50%
  ,  rgba(52, 52, 52, 0.8) 90%, rgba(44, 44, 44, 0.1) 100%); */
  height: var(--mobile-nav-height);
  width: 100%;
  display: flex;
  justify-content:space-between;
  font-size: 0.9rem;
  position: fixed;
  top: 0;
  z-index: 200;
  transition: top 1.2s; /* Transition effect when sliding down (and up) */
  /* border: 1px red solid; */
}

.left_container {
  /* border: 1px solid red; */
  width: 126px;
  display: flex;
  align-items: center;
  padding-left: 1rem;
}

.center_container {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
}

.right_container {
  /* border: 1px solid red; */
  width: 126px;
  display: flex;
  align-items: center;
  justify-content: right;
  padding-right: 1rem;
}

.desktop_username {
  margin-top: 0.5rem;
  margin-left: 0.8rem;
  font-size: 0.7rem;
}

/* PaletteX desktop*/
.navbar_desktop_logo {
  font-family: var(--google-font-family);
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
}

.fa_typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav_menu {
  display: flex;
  justify-content:space-around;
  /* grid-gap: 1.9rem; */
  list-style: none;
  text-align: center;
  /* width: min(100%, 62.5rem); */
  width: 40rem;
}

.nav_links {
  font-family: var(--google-font-family);
  /* border: 1px solid blue; */
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0 0 0;
}

.account_icon_desktop, .cart_icon, .desktop_exit_btn{
  /* border: 1px solid blue; */
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.5rem 0 0;
}

.nav_links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

.bars {
  color: #fff;
}

.nav_links_mobile {
  display: none;
}

.menu_icon {
  display: none;
  color: aliceblue;
}

.cart_icon {
  color: aliceblue;
}

.navbar_mobile_logo {
  display: none;
}

.account_icon_mobile {
  display: none;
}

@media screen and (max-width: 960px) {
  .nav_menu {
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    padding-top: 2.5rem;
    width: 100%;
    height: 100vh;
    position: absolute;
    /* top: var(--mobile-nav-height); */
    top: 2.7rem;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    font-size: 1rem;
    grid-gap: 3rem;
    background: linear-gradient(180deg,  rgba(44, 44, 44, 0.1) 0%, rgba(27, 27, 27, 0.9) 3%
    ,  rgba(2, 2, 2, 0.9) 20%, rgba(2, 2, 2, 0.9) 100%);
    /* border: 1px solid red; */
  }

  .nav_menu.active {
    background: linear-gradient(180deg,  rgba(44, 44, 44, 0.1) 0%, rgba(27, 27, 27, 0.9) 3%
    ,  rgba(2, 2, 2, 0.9) 20%, rgba(2, 2, 2, 0.9) 100%);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav_links {
    text-align: center;
    width: 100%;
    display: table;
  }

  .nav_links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar_desktop_logo {
    font-family: var(--google-font-family);
    display: none;
    justify-content: center;
  }

  .navbar_mobile_logo {
    font-family: var(--google-font-family);
    display: block;
    color: aliceblue;
    font-size: 1.3rem;
  }

  .menu_icon {
    display:contents;
  }

  .times {
    color: #fff;
    font-size: 2rem;
  }

  .nav_links_mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav_links_mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }

  .account_icon-desktop, .desktop_username, .desktop_exit_btn {
    display: none;
  }

  .account_icon_mobile {
    display: block;
  }

  .account_icon_mobile_link {
    color: aliceblue;
  }

  .cart_icon {
    padding: 0;
    margin-top: 0rem;
  }
}