* {
  margin: 0;
  padding: 0;

}

.main {
  width: 100%;
  height: 100vh;
  /* border: 1px solid yellow; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  /* border: 1px solid yellow; */
}

.content {
  border: 1px solid red;
  position: absolute;
  width: 100%;
  height: 85vh;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  Color: white;
}

.hero_img {
  /* background-image: url("../../img/hero07.jpg"); */
  background-position: center;
  background-repeat: no-repeat;
  /* height: 816px; */
  height: 100vh;
  background-size: cover;
  /* opacity: 50%; */
  border: 1px solid red;
}

.one_text {
  font-size: 1.2rem;
  /* font-weight:500; */
  text-shadow: 1px 0 rgb(56, 56, 56);
}

.two_text {
  font-size: 1.2rem;
  /* font-weight:600; */
  text-shadow: 1px 0 rgb(56, 56, 56);
}

.three_text {
  font-size: 1.2rem;
  /* font-weight:500; */
  text-shadow: 1px 0 rgb(56, 56, 56);
}

.smart_phone {
  width: 300px;
  /* margin-bottom: 0.5rem; */
}

.title_1, .title_2 {
  /* Color: rgb(255, 92, 92); */
  font-family: 'Philosopher', sans-serif;
  font-size: 1.2rem;
  text-align: center;
  margin: 0.3rem;
  /* font-size: 72px; */
  background: -webkit-linear-gradient(75deg, #bf9693,#c25952,#eee12d, #d54036,#ba5378);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes fadeInImg {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes fadeInTitle {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeInImg {
  animation: fadeInImg 1.5s ease forwards;
}

.fadeInTitle {
  animation: fadeInTitle 2s ease forwards;
}

@media screen and (max-width: 960px) {
  .main {
    height: 90vh;
  }

  video {
    height: 90vh;
  }
  .hero_img {
    height: 90vh;
  }
  .content {
    /* height: 90vh; */
  }

  .its_time_to_text {
    font-size: 0.9rem;
    padding-top: 0rem;
  }

  .wallpaper_text {
    font-size: 0.9rem;
    padding-bottom: 3.2rem;
  }

  .smart_phone {
    width: 250px;
  }

}