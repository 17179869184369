.frame {
  /* border: 1px dashed yellow ; */
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: right;
  padding-right: 10px;
  align-items: center;
  /* margin: 10%; */
  /* box-shadow: 3px 3px 5px 2px rgba(142, 142, 142, 0.25); */
}

.main_container {
  /* border: 1px solid green; */
  width: 85%;
  display: grid;
  grid-template-columns: 6fr 1fr;
}

.left_container {
  /* border: 1px solid green; */
  position: relative;
}

.right_container {
  /* border: 1px solid red; */
  padding-top: 50px;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.image {
  margin: 0;
  /* object-fit: contain; */
  object-fit: cover;
  height: 450px;
  border-radius: 0.7rem;
  border: 1px solid rgb(71, 71, 71);
}

.text {
  width: 160px;
  height: 1.6rem;
  font-size: 0.7rem;
  color: rgb(241, 241, 241);
  word-wrap: break-word;
  overflow:hidden;
}

.download_icon {
  width: 33px;
}

.download_icon:hover {
  cursor: pointer;
}

.free_text {
  font-family: 'Philosopher', sans-serif;
  font-size: 0.9rem;
  margin: 0px 0px 10px 0px;
  font-weight: bold;
  color: #3fe303;
}

.add_cart {
  /* border: 1px solid green; */
  /* margin: 0 5px 0 0; */
  width: 33px;
}

.add_cart:hover {
  cursor: pointer;
}

.price {
  /* border: 1px solid green; */
  font-size: 0.7rem;
  margin: 0px 0px 10px 0px;
  font-weight: bold;
  color: #ffdd00;
}

.live_play_btn {
  /* border: 1px solid green; */
  margin: 20px 3px 0 0;
  width: 40px;
}

.overlayImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease;
  opacity: 0.8;
  z-index: 1;
}

@media screen and (max-width: 960px) {
  .image {
    /* border-radius: 0.8rem; */
    height: 100%;
    width: 100%;
  }

  .frame {
    padding-right: 30px;
  }
}