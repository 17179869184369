
.top_container {
  /* border: 2px dashed yellow ; */
  width: 100%;
  height: 2.7rem;
}

.container {
  /* border: 1px solid rgb(208, 255, 0); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:flex-start;
  /* height: 2000px; */
}

.left_block {
  /* border: 1px solid red; */
  width:40%;
  /* height: 90vh; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.middle_block {
  /* border: 1px solid rgb(0, 13, 255); */
  width:40%;
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
}

.image {
  margin: 0;
  height: 95%;
  width: auto;
  border: 1px solid gray;
  border-radius: 1rem;
}

img {
  /* width: 80vh; */
}

.right_block {
  /* border: 1px solid red; */
  width: 40%;
  height: 0;
  display: flex;
  justify-content: center;
}

.inner_block {
  margin-top: 1rem;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 90%;
  /* height: 70%; */
  /* justify-content: space-between; */
}

.item_price {
  color: green;
}

.special_discount {
  width: 100%;
  color: green;
  font-size: 1.1rem;
}

.item_name {
  width: 100%;
  font-size: 0.9rem;
}

.add_button {
  width: 100%;
  height: 2.8rem;
  border-radius: 1.5rem;
  border-style: none;
  background-color: black;
  color: aliceblue;
  font-size: 1rem;
}

.description {
  width: 100%;

}


@media (max-width: 960px) {
  .container {
    /* border: 1px solid rgb(13, 0, 255); */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* align-items:normal; */
    width: 100%;
    /* justify-content:space-around; */
    /* height: 110vh; */
  }

  .left_block {
    /* border: 1px solid red; */
    width:100%;
    /* height: 100vh; */
  }

  .right_block {
    /* border: 1px solid red; */
    width: 100%;
    /* height: 110vh; */
    display: flex;
    justify-content: center;
  }

  .middle_block {
    /* border: 1px solid red; */
    height: 79vh;
  }
}