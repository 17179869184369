.container {
  color: aliceblue;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.wallpaper_setup {
  width: min(100%, 600px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.title {
  color: aliceblue;
  font-size: 2rem;
  margin: 3rem 0 1rem 0; /* Adjusted margin */
  text-align: center; /* Centered text */
}

.title_img {
  width: 70%;
  margin: 1rem 0 3rem 0; /* Adjusted margin */
  border-radius: 0.5rem;
}

.instruction_img {
  width: 60%;
  margin: 1rem;
  border-radius: 0.5rem;
  border: 1px solid rgb(80, 80, 80);
}

.content li {
  display: flex;
  flex-direction: column; /* Changed to column layout */
  align-items: center; /* Centered items */
  margin-bottom: 2rem; /* Adjusted margin */
  text-align: justify; /* Justify text */
}

@media screen and (max-width: 960px) {
  .title {
    font-size: 1.5rem; /* Adjusted font size */
    margin: 2rem 0 1rem 0; /* Adjusted margin */
  }

  .title_img {
    width: 90%; /* Adjusted width */
    margin-bottom: 2rem; /* Adjusted margin */
  }

  .instruction_img {
    width: 70%; /* Adjusted width */
    margin-bottom: 3rem; /* Adjusted margin */
  }

  .content li {
    font-size: 1rem; /* Adjusted font size */
    margin-bottom: 2rem; /* Adjusted margin */
  }
}
