.top_container {
  /* border: 2px dashed yellow ; */
  width: 100%;
  height: 2.7rem;
}

.container {
  /* border: 1px solid green; */
  color: aliceblue;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  /* height: 100%; */
  height: 100vh;
  margin-top: 1rem;
}

.login_form {
  /* border: 1px solid red; */
  width: min(100%, 450px);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-top: 3rem; */
}

.header {
  /* border: 1px solid red; */
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 1rem;
  color: aliceblue;
}

.register, .register a:visited, .register a:link {
  color: rgb(242, 242, 242);
  font-size: 0.8rem;
  display: flex;
  justify-content:space-around;
  align-items: center;
}

.create_account {
  display: flex;
  flex-direction: row;
  font-size: 1rem;
}

.create_account strong{
  font-size: 1.1rem;
}

.login_button, .reload_button{
  color: aliceblue;
  padding: 0.8rem;
  border-radius: 0.3rem;
  margin: 1.1rem 0.5rem 1.5rem 0.5rem;
  font-size: 1rem;
  border-style: none;
}



.notchedOutline {
  border-color:rgb(205, 205, 205) !important;
}

.error_msg,
.success_msg {
	margin: 0.2rem 0 1rem 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
   -webkit-box-shadow: 0 0 0 30px inset !important;
   -webkit-text-fill-color: white !important;
}
