
* {
  margin: 0;
  padding: 0;
}
.top_container {
  /* border: 2px dashed yellow ; */
  width: 100%;
  height: 2.7rem;
}

.title {
  color:aliceblue;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.container {
  /* border: 1px solid white; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  padding: 3%;
  margin-bottom: 250px;
}

.list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  /* border: 1px solid green; */
  width: 50%;
}

.list li {
  /* border: 1px solid green; */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background: #272727;
  border-radius: 0.5rem;
  margin: 0.5rem 0 0.5rem 0 ;
}
.left_block {
  margin: 0.5rem;
  /* border: 1px solid rgb(25, 0, 255); */
  width: 20%;
  display:flex;
}
.right_block {
  width: 80%;
  /* border: 1px solid rgb(255, 0, 179); */
  font-size: 0.7rem;
  padding: 0.7rem 0.7rem 0.7rem 0.2rem;
  display: flex;
  flex-direction: column;
  justify-content:space-between;
}

img {
  margin: 0;
  border-radius: 0.3rem;
  width: 100%;
  /* object-fit: cover; */
}

.name_price_block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #e4e4e4;
}

.item_name {
  color: rgb(217, 217, 217);
  /* height: 2rem; */
  font-size: 0.9rem;
  width: 80%;
}

.item_price {
  font-size: 0.9rem;
  font-weight: bold;
}

.catalog_remove_block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #e4e4e4;
}


.list .remove_button {
  border-radius: 0.2rem;
  border: none;
  padding: 0.3rem;
  background-color: #3d3d3d;
  color: rgb(160, 160, 160);
  outline: none;
}

.list .remove_button:hover {
  opacity: 1;
  cursor: pointer;
}

.checkout_container {
  /* border: 1px solid red; */
  width: 30%;
  height: 14rem;
  /* width: 100%; */
  display: flex;
  align-items:self-end;
}

.upper_container {
  /* border: 1px solid red; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-bottom: 1rem;
}

.checkout_box {
  /* border: 1px solid red; */
  background: #181818;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 13rem;
  width: 100%;
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 0.5rem;
  color:#e4e4e4;
}

.checkout_info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1 3;
  height: 20rem;
  width: 95%;
  border: 2px solid #8f8f8f;
  border-radius: 0.3rem;
  padding: 0.5rem;
  margin: 0.5rem;
}

.checkout_box button {
  border-style: none;
  color: white;
  background: #0089cc;
  width: 100%;
  height: 2.5rem;
  border-radius: 0.3rem;
  padding: 0.5rem;
}

.checkout_box a {
  width: 90%;
  height: 2rem;
}

.checkout_box button:hover {
  opacity: 0.8;
  cursor: pointer;
}

.tags {
  display: flex;
  align-items: center;
}

.tags span {
  padding-right: 0.3rem;
  font-size: 0.7rem;
  text-decoration:underline;
  color: #8f8f8f;
}

.items_count_box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}

.total_price_box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  border-top: 1px solid rgb(133, 133, 133);
  padding-top: 0.6rem;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}

.price_container {
  display: flex;
  flex-direction: row;
  align-items:baseline;
}

.currency {
  color: #a3a3a3;
  font-size: 0.8rem;
  padding-right: 0.5rem;
}

.items_count_box span {
  font-size: 0.8rem;
}

@media screen and (max-width: 960px) {
  .container {
    flex-direction: column;
    padding: 3%;
    align-items: center;
  }
  .list {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    width: 95%;
  }

  .name_price_block {
    font-size: 0.5rem;
  }

  .checkout_container {
    width: 95%;
    align-items:self-start;
  }

  .checkout_box {

    height: 10rem;
    width: 100%;
    margin: 0.5rem 0 0 0;
  }

  .checkout_box a {
    width: 95%;
  }

  .left_block {
    margin: 0.5rem;
    /* border: 1px solid rgb(25, 0, 255); */
    width: 60%;
  }
}